// variables

$md: 550px;
$green: #45a016;
$orange: #ef611b;
$borderColor: #333;

// reset
::before,
::after {
  box-sizing: border-box;
}

html {
  line-height: 1.5;
}

body {
  margin: 0;
}

p {
  margin: 0 0 1em;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

button,
input,
select {
  margin: 0;
}

button {
  overflow: visible;
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

fieldset {
  border: 1px solid #a0a0a0;
  padding: 0.35em 0.75em 0.625em;
}

input {
  overflow: visible;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

// fonts

@font-face {
	font-family: system-ui;
	font-style: normal;
	font-weight: 400;
	src: local(".SFNS-Regular"), local(".SFNSText-Regular"), local(".HelveticaNeueDeskInterface-Regular"), local(".LucidaGrandeUI"), local("Segoe UI"), local("Ubuntu"), local("Roboto-Regular"), local("DroidSans"), local("Tahoma");
}

@font-face {
	font-family: system-ui;
	font-style: italic;
	font-weight: 400;
	src: local(".SFNS-Italic"), local(".SFNSText-Italic"), local(".HelveticaNeueDeskInterface-Italic"), local(".LucidaGrandeUI"), local("Segoe UI Italic"), local("Ubuntu Italic"), local("Roboto-Italic"), local("DroidSans"), local("Tahoma");
}

@font-face {
	font-family: system-ui;
	font-style: normal;
	font-weight: 700;
	src: local(".SFNS-Bold"), local(".SFNSText-Bold"), local(".HelveticaNeueDeskInterface-Bold"), local(".LucidaGrandeUI"), local("Segoe UI Bold"), local("Ubuntu Bold"), local("Roboto-Bold"), local("DroidSans-Bold"), local("Tahoma Bold");
}

@font-face {
	font-family: system-ui;
	font-style: italic;
	font-weight: 700;
	src: local(".SFNS-BoldItalic"), local(".SFNSText-BoldItalic"), local(".HelveticaNeueDeskInterface-BoldItalic"), local(".LucidaGrandeUI"), local("Segoe UI Bold Italic"), local("Ubuntu Bold Italic"), local("Roboto-BoldItalic"), local("DroidSans-Bold"), local("Tahoma Bold");
}

@font-face {
  font-family: gothic;
	font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/leaguegothic-regular-webfont.woff");
}

// global styles

body {
  font-family: system-ui;
  color: #333;
  background-color: #fafafa;
}

.wrap {
  $borderThickness: 8px;
  position: relative;
  height: calc(100vh - #{$borderThickness * 2});
  display: grid;
  align-content: center;
  justify-content: center;
  gap: 20px;
  border: $borderThickness solid $borderColor;
  &:after {
    position: absolute;
    bottom: -$borderThickness;
    content: '';
    height: $borderThickness;
    width: 100%;
    background-color: $borderColor;
  }
}

h1 {
  font-family: gothic;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: .02em;
}

a {
  color: $green;
}

header {
  text-align: center;
  h1 {
    margin: 0;
    font-size: 5rem;
  }
}

section {
  max-width: 450px;
  padding: 20px;
  border-radius: 5px;
  @media (min-width: $md) {
    padding: 30px;
    background-color: #fff;
    border: 1px solid $borderColor;
  }

  ul > li {
    position: relative;
    margin-left: 30px;
    &::before {
      position: absolute;
      left: -28px;
      content: '🥕 ';
    }
  }
}

form {
  margin: 30px 5px 0;
  display: grid;
  gap: 10px 20px;
  @media (min-width: $md) {
    grid-template-columns: 1fr 150px;
  }

  > div:first-child,
  #mce-responses {    
    grid-column: 1 / -1;
  }
}



input {
  width: 100%;
  font-size: 1.3rem;
  padding: 0.5em;
  border: 1px solid #666;
  border: none;
  background-color: #eee;
  border-radius: 5px;
  &[type="email"]:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px $green;
    background-color: lighten($green, 55%);
  }
  &[type="submit"] {
    outline: none;
    background-color: $orange;
    color: #fff;
    cursor: pointer;
    &:active {
      background-color: darken($orange, 10%);
    }
  }
}

.response {
  margin-top: 20px;
  padding: 12px 16px;
  font-size: .8em;
  background-color: #feffda;
}